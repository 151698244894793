import React, { useState, useEffect } from "react";
import { handleGetRequest } from "../../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import remarksImage from "../../../assets/png/remarks.png";
import { useFormik } from "formik";
import { handlePostRequest } from "../../../services/PostTemplate";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loading from "../../../components/Loader";
import { InputTextarea } from "primereact/inputtextarea";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import NavigationComponent from "../../../components/NavigationComponent";
import { Carousel } from "primereact/carousel";
import { Dialog } from "primereact/dialog";
import EditViewParts from "../../Parts/Components/EditViewParts";
import EditViewVariant from "../../SystemSetting/Pages/VariantManagement/EditViewVariant";
import { baseURL } from "../../../Config";
import * as Yup from "yup";

const ViewQuery = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [loadingBtn, setLoadingBtn] = useState(false);

    const { id: quotation_id } = useParams();

    const [queryData, setQueryData] = useState("");
    const [recordId, setRecordId] = useState("");
    const [requestData, setRequestData] = useState("");
    const [detailsArray, setDetailsArray] = useState([]);
    const [response, setResponse] = useState(false);
    const [loading, setLoading] = useState(false);
    const [displayDialog, setDisplayDialog] = useState(false);

    let parsedUserId = null;
    const status = [
        // { label: "Approved", value: "approved" },
        { label: "On Hold", value: "onhold" },
        { label: "Rejected", value: "rejected" },
        { label: "Processed", value: "processed" },
        // { label: "Delivered", value: "delivered" },
    ];
    const userIdStr = JSON.parse(localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails"));
    const userId = userIdStr?.data?.id;

    const getQuotationsyId = async () => {
        setLoading(true);

        const response = await dispatch(handleGetRequest(`/api/v1/qoutation/byId/${quotation_id}`, false));
        setResponse(response?.data);
        setDetailsArray(response?.data?.details);
        const keyData = response?.data;
        formik.setFieldValue("status", keyData?.quotationStatus || "");
        setQueryData(keyData?.clientQuery);
        setRequestData(keyData?.clientRequest);

        setLoading(false);
    };

    useEffect(() => {
        if (quotation_id) {
            getQuotationsyId();
        }
    }, [quotation_id]);

    const validationSchema = Yup.object().shape({
        reply: Yup.string()?.matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed"),
        remarks: Yup.string()?.matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            remarks: "",
            reply: "",
            status: "",
        },

        onSubmit: async (data) => {
            setLoadingBtn(true);

            let newData = {
                remarks: data?.remarks && data?.remarks !== response?.remarks?.[0]?.remarks ? data?.remarks : undefined,
                reply: data?.reply ? data?.reply : undefined,
                status: data?.status.toLocaleLowerCase() !== "pending" && data?.status.toLocaleLowerCase() !== "approved" ? data?.status : undefined,
                quotationId: quotation_id,
                userId: userId,
            };
            const res = await dispatch(handlePostRequest(newData, "/api/v1/remarks/create", false, true));
            if (res.status === 200) {
                getQuotationsyId();
                formik.setFieldValue("reply", "");
                history.push("/querymanagement");
            }
            setLoadingBtn(false);
        },
    });

    const createdDateAndTimeBodyTemplate = (createdDate) => {
        const isoDate = createdDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, "0");
        const dd = String(date.getDate()).padStart(2, "0");
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");

        let ampm = "AM";
        if (hours >= 12) {
            ampm = "PM";
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p className="text-sm">{formattedDateTime}</p>;
    };

    const responsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 3,
            numScroll: 3,
        },
    ];

    const carouselVehicleObj = (detailsArray) => {
        return (
            <div className="p-field col-12 md:col-12">
                <div className="no-header query-table-main">
                    <div className="queryRow">
                        <h3>{detailsArray?.variantName}</h3> <img crossorigin="anonymous" src={`${baseURL}/${detailsArray?.VariantImg[0]?.path}`} style={{ borderRadius: "50px" }} width="90px" height="90px" alt="Variant Image" />
                    </div>
                    <div className="queryRow">
                        <h6>Maker Name</h6>
                        <p>{detailsArray?.variantMakerName}</p>
                    </div>
                    <div className="queryRow">
                        <h6>Model Name</h6>
                        <p>{detailsArray?.variantModelName}</p>
                    </div>{" "}
                    <div className="queryRow">
                        <h6>Category</h6>
                        <p>{detailsArray?.variantCategoryName}</p>
                    </div>
                    <div className="queryRow">
                        <h6>Year</h6>
                        <p>{detailsArray?.variantYear}</p>
                    </div>
                    <div className="queryRow">
                        <h6>Power Type</h6>
                        <p>{detailsArray?.variantPowerType}</p>
                    </div>
                    <div className="queryRow">
                        <h6>Transmission</h6>
                        <p>{detailsArray?.variantTransmission}</p>
                    </div>
                    <div className="queryRow">
                        <h6>Features</h6>
                        <p className="ml-4">{detailsArray?.variantFeature}</p>
                    </div>
                    <div className="flex justify-content-center">
                        <Button
                            className="Save-Button"
                            label="View Details"
                            type="button"
                            onClick={() => {
                                handleDialog(detailsArray?.variantId);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };
    const carouselPartsObj = (detailsArray) => {
        return (
            <div className="p-field col-12 md:col-12">
                <div className="no-header query-table-main">
                    <div className="queryRow">
                        <h3>{detailsArray?.partName}</h3>
                        <img crossorigin="anonymous" src={`${baseURL}/${detailsArray?.partImg?.[0]?.path}`} style={{ borderRadius: "50px" }} width="90px" height="90px" alt="Part Image" />
                    </div>

                    <div className="queryRow">
                        <h6 className="font-bold">Total Parts</h6>
                        <p className="text-wrap font-bold">{response?.details?.length}</p>
                    </div>
                    <div className="queryRow">
                        <h6>Part Number</h6>
                        <p className="text-wrap">{detailsArray?.partNumber}</p>
                    </div>
                    <div className="queryRow">
                        <h6>SKU Number</h6>
                        <p className="text-wrap">{detailsArray?.partSkuNumber}</p>
                    </div>

                    <div className="queryRow">
                        <h6>Price</h6>
                        <p className="text-wrap">{detailsArray?.partCost ? detailsArray?.partCost : "-"}</p>
                    </div>
                    <div className="queryRow">
                        <h6>Part Variant Name</h6>
                        <p>{detailsArray?.variantName ? detailsArray?.variantName : "-"}</p>
                    </div>
                    <div className="queryRow">
                        <h6>Dimension</h6>
                        <p className="text-wrap">{detailsArray?.partDimension ? detailsArray?.partDimension : "-"}</p>
                    </div>
                    <div className="flex justify-content-center">
                        <Button
                            className="Save-Button"
                            label="View Details"
                            type="button"
                            onClick={() => {
                                handleDialog(detailsArray?.partId);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const handleDialog = (partId) => {
        setRecordId(partId);
        setDisplayDialog(true);
    };

    const onHide = () => {
        setDisplayDialog(false);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const headerText = "Details";
    const header = <span className="title-border">{headerText}</span>;

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Dialog resizable={false} header={header} visible={displayDialog} maskClassName="zindex8888" blockScroll style={{ width: "50vw" }} onHide={() => onHide()} draggable={false}>
                        {response?.isVehicle ? <EditViewVariant onHide={onHide} isEditable={false} variantdataId={recordId} /> : <EditViewParts onHide={onHide} isEditable={false} partsdataId={recordId} />}
                    </Dialog>
                    <form onSubmit={formik.handleSubmit}>
                        <NavigationComponent title={`View Query`} />
                        <div className="p-fluid p-formgrid grid  mb-5 m-0 card-ml-1 dark-bg">
                            <div className="col-6">
                                <div className="p-field col-12 md:col-12">
                                    <Carousel value={detailsArray} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular={false} itemTemplate={response?.isVehicle ? carouselVehicleObj : carouselPartsObj} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="p-field col-12 md:col-12">
                                    <div className="remarksSection ">
                                        <h3>Remarks</h3>
                                        <div className="remarksImage">
                                            <img src={remarksImage} alt="Image Alt Text" style={{ height: "100px", width: "120px" }} />
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex field col-12 md:col-10 mx-5 align-items-baseline" style={{ paddingLeft: "0", marginBottom: "0", paddingBottom: "0" }}>
                                            <label className="mb-0"> Client's Name: </label>
                                            <p className="text-900 ml-2 pt-0">{response?.clientName}</p>
                                        </div>
                                        <div className="flex field col-12 md:col-10 mx-5 align-items-baseline" style={{ paddingLeft: "0", marginBottom: "0", paddingBottom: "0" }}>
                                            <label className="mb-0"> Client's Email: </label>
                                            <p className="text-900 ml-2">{response?.clientEmail}</p>
                                        </div>
                                        <div className="flex field col-12 md:col-10 mx-5 align-items-baseline" style={{ paddingLeft: "0", marginBottom: "0", paddingBottom: "0" }}>
                                            <label className="mb-0"> Client's Phone Number: </label>
                                            <p className="text-900 ml-2">{response?.clientPhone}</p>
                                        </div>
                                        <div className="field col-12 md:col-10 mx-5" style={{ paddingLeft: "0", marginBottom: "0", maxHeight: "150px", overflow: "auto", wordWrap: "break-word", whiteSpace: "normal" }}>
                                            <label> Client's Query: </label>
                                            <span className="Label__Required"></span>
                                            <p className="text-900 ">{queryData}</p>
                                        </div>
                                        <div className="field col-12 md:col-10 mx-5" style={{ paddingLeft: "0", marginBottom: "0", maxHeight: "150px", overflow: "auto", wordWrap: "break-word", whiteSpace: "normal" }}>
                                            <label> Client's Message: </label>
                                            <span className="Label__Required"></span>
                                            <p className="text-900">{requestData}</p>
                                        </div>
                                        <div className="field col-12 md:col-6 mx-5" style={{ paddingLeft: "0", marginBottom: "0" }}>
                                            <label> Status </label>
                                            <span className="Label__Required"></span>
                                            <Dropdown
                                                className={classNames({ "p-invalid": isFormFieldValid("status") }, "Dropdown__Round")}
                                                id="status"
                                                name="status"
                                                options={status}
                                                optionValue="value"
                                                optionLabel="label"
                                                filter
                                                showClear={!!formik?.values?.status}
                                                filterBy="label"
                                                showFilterClear={true}
                                                resetFilterOnHide={true}
                                                onChange={(e) => {
                                                    formik?.setFieldValue("status", e.target.value ? e.target.value : "");
                                                }}
                                                value={formik.values.status}
                                                placeholder={response?.quotationStatus ? response.quotationStatus.charAt(0).toUpperCase() + response.quotationStatus.slice(1) : ""}
                                                disabled={response?.quotationStatus == "rejected"}
                                            />
                                            {getFormErrorMessage("status")}
                                        </div>
                                        <div className="field col-12 md:col-10 mx-5" style={{ paddingLeft: "0", marginBottom: "0" }}>
                                            <label> Notes </label>
                                            <span className="Label__Required"></span>
                                            <InputTextarea
                                                id="remarks"
                                                name="remarks"
                                                value={formik?.values?.remarks}
                                                onChange={formik.handleChange}
                                                placeholder="Enter Remarks...."
                                                maxLength={200}
                                                rows={3}
                                                cols={10}
                                                autoResize
                                                className={classNames({ "p-invalid": isFormFieldValid("remarks") }, "textAreaField")}
                                                disabled={response?.quotationStatus == "rejected"}
                                            />
                                            {getFormErrorMessage("remarks")}
                                        </div>
                                        <div className="field col-12 md:col-10 mx-5" style={{ paddingLeft: "0" }}>
                                            <label> Reply To Customer </label>
                                            <span className="Label__Required"></span>
                                            <InputTextarea
                                                id="reply"
                                                name="reply"
                                                value={formik?.values?.reply}
                                                onChange={formik.handleChange}
                                                placeholder="Enter Reply...."
                                                maxLength={200}
                                                rows={3}
                                                cols={10}
                                                autoResize
                                                className={classNames({ "p-invalid": isFormFieldValid("reply") }, "textAreaField")}
                                                disabled={response?.quotationStatus == "rejected"}
                                            />
                                            {getFormErrorMessage("reply")}
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={() => history.push("/querymanagement")} />

                                        {response?.quotationStatus !== "rejected" ? <Button className="Save-Button ml-2" type="submit" label="Add" loading={loadingBtn} disabled={!formik?.values?.remarks && !formik?.values?.reply} /> : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-ml-1 dark-bg p-5">
                            <div className="flex">
                                <div className="col-6 border-round card-content reply-history">
                                    <p className="text-900 font-semibold text-xl border-bottom-1">Replies History</p>

                                    <div className="content chat-container">
                                        {response?.message?.map((item) => {
                                            return (
                                                <>
                                                    {item?.clientReply && (
                                                        <div className="chat-bubble">
                                                            <div className="customer">
                                                                <p className="mb-2">{item?.clientReply}</p>
                                                                {createdDateAndTimeBodyTemplate(item?.createdDate)}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {item?.reply && (
                                                        <div className="chat-bubble">
                                                            <div className="my-reply">
                                                                <p className="mb-2">{item?.reply}</p>
                                                                {createdDateAndTimeBodyTemplate(item?.createdDate)}
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="col-6 border-round card-content">
                                    <p className="text-900 font-semibold text-xl border-bottom-1">Notes History</p>
                                    <div className="content">
                                        <ul>
                                            {response?.remarks?.map(
                                                (item, index) =>
                                                    item?.remarks && (
                                                        <li key={index} className="text-900 text-wrap mr-3 mb-3">
                                                            <span className="font-semibold">{createdDateAndTimeBodyTemplate(item?.createdDate)}</span>
                                                            {item?.remarks}
                                                        </li>
                                                    )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </>
            )}
        </>
    );
};

export default ViewQuery;
