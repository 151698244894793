import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import Modal from "../../components/modal/Modal";
import { handleGetRequest } from "../../services/GetTemplate";
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import editIcon from "../../../src/assets/png/edit.png";
import deleteIcon from "../../../src/assets/png/delete.png";
import viewIcon from "../../assets/png/view.png";
import { baseURL } from "../../Config";
import Loading from "../../components/Loader";
import AddParts from "./Components/AddParts";
import EditViewParts from "./Components/EditViewParts";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { handlePutRequest } from "../../services/PutTemplate";
import { Dropdown } from "primereact/dropdown";
import NavigationComponent from "../../components/NavigationComponent";

const ManagePart = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [switchModal, setSwitchModal] = useState(false);
    const [switchData, setSwitchData] = useState(null);
    const [allparts, setAllParts] = useState([]);
    const [partsData, setPartsData] = useState([]);
    const [partsdataId, setPartsDataId] = useState();
    const [displayDialog, setDisplayDialog] = useState(false);
    const [currentModal, setCurrentModal] = useState(null);
    const [isEditable, setIsEditAble] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [statusValue, setStatusValue] = useState("all");
    const [filteredParts, setFilteredParts] = useState([]);

    const statusOptions = [
        { id: "all", name: "All" },
        { id: "active", name: "Active" },
        { id: "inactive", name: "Inactive" },
    ];

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const dispatch = useDispatch();

    const userIdStr = localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails");

    const userId = JSON.parse(userIdStr);
    const userRole = userId?.data?.role;

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ""));
    };

    const handleInputSwitch = async () => {
        setLoadingBtn(true);
        const apiImages = switchData?.images?.map((item) => {
            return item?.img_path;
        });
        let obj = {
            oldImages: apiImages,
            isActive: switchData?.partIsActive ? false : true,
        };

        const response = await dispatch(handlePutRequest(obj, `/api/v1/parts/update/${switchData?.part_id}`, false, true));
        if (response?.status === 200) {
            getPartList();
        }
        setLoadingBtn(false);
        handleSwitchModalClose();
    };
    const statusTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                <InputSwitch
                    className="edit-btn ml-1 mt-2"
                    checked={rowData.partIsActive == "1"}
                    onChange={() => {
                        handleSwitchModalOpen();
                        setSwitchData(rowData);
                    }}
                />
            </div>
        );
    };
    const actionTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                {!userRole?.toLowerCase().includes("user") && (
                    <Button className="edit-btn ml-1 px-2" onClick={() => handleEditCars(rowData)}>
                        <img src={editIcon} alt="Edit" />
                    </Button>
                )}

                <Button className="edit-btn ml-1 px-2" onClick={() => handleViewPart(rowData)}>
                    <img src={viewIcon} alt="view" />
                </Button>
                {!userRole?.toLowerCase().includes("user") && (
                    <Button
                        className="edit-btn ml-1 px-2"
                        onClick={() => {
                            handleOpenModal();
                            setPartsData(rowData.part_id);
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" />
                    </Button>
                )}
            </div>
        );
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleSwitchModalOpen = () => {
        setSwitchModal(true);
    };
    const handleSwitchModalClose = () => {
        setSwitchModal(false);
    };

    const onHide = () => {
        setCurrentModal(null);
        setDisplayDialog(false);
    };

    const handleDialog = () => {
        setCurrentModal("Add");
        setDisplayDialog(true);
    };

    const handleEditCars = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setIsEditAble(true);
        setPartsDataId(rowData?.part_id);
    };

    const handleViewPart = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setIsEditAble(false);
        setPartsDataId(rowData?.part_id);
    };

    const getPartList = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest("/api/v1/parts/getAll", false));
        if (response) {
            setAllParts(response?.data);
        }
        setLoading(false);
    };

    useEffect(() => {
        getPartList();
    }, []);
    useEffect(() => {
        const updatedResponse = allparts?.filter((item) => {
            if (statusValue === "active") return item?.partIsActive;
            else if (statusValue === "inactive") return !item?.partIsActive;
            else return item;
        });
        setFilteredParts(updatedResponse);
    }, [statusValue, allparts]);

    const handleDeleteCars = async () => {
        setLoadingBtn(true);
        const response = await dispatch(handleDeleteRequest(`/api/v1/parts/delete/${partsData}`, true, true));
        if (response?.status === 200) {
            getPartList();
        }
        setLoadingBtn(false);
        handleCloseModal();
    };

    const optionTemplate = (option) => {
        return <div className={option.className}>{option.name}</div>;
    };
    const selectedItemTemplate = (option) => {
        if (option) {
            return <div className={option.className}>{option.name}</div>;
        }
        return <span>Select Status</span>;
    };
    const ImageTemplate = (rowData) => {
        let imageUrl = rowData?.images[0]?.img_path;
        return (
            <React.Fragment>
                <img
                    style={{ borderRadius: "10px" }}
                    crossorigin="anonymous"
                    src={`${baseURL}/${imageUrl}`}
                    width="70px"
                    height="45px"
                    alt="Part Image"
                    onError={(e) => {
                        e.target.onerror = null; // Prevents looping error if fallback also fails
                        e.target.src = `${baseURL}/images/default-part.jpeg`; // Fallback image
                    }}
                />
            </React.Fragment>
        );
    };
    const costTemplate = (rowData) => {
        return ` ${rowData?.partCost} ${rowData?.currencyCode}`;
    };
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.part_createdDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, "0");
        const dd = String(date.getDate()).padStart(2, "0");
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");

        let ampm = "AM";
        if (hours >= 12) {
            ampm = "PM";
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const headerText = currentModal === "Add" ? "Add Part" : isEditable === true ? "Edit Part" : "View Part";
    const header = <span className="title-border">{headerText}</span>;

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Dialog resizable={false} header={header} visible={displayDialog} blockScroll style={{ width: "50vw" }} maskClassName="zindex8888" onHide={() => onHide()} draggable={false}>
                        {currentModal === "Add" ? <AddParts onHide={onHide} getPartList={getPartList} /> : currentModal === "EditView" && <EditViewParts onHide={onHide} partsdataId={partsdataId} getPartList={getPartList} isEditable={isEditable} />}
                    </Dialog>

                    <div>
                        <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleDeleteCars} rowData={partsData} type="delete" loadingBtn={loadingBtn} headerText="Are you sure you want to delete this Part?" />
                    </div>
                    <div>
                        <Modal
                            isOpen={switchModal}
                            onClose={handleSwitchModalClose}
                            onConfirm={handleInputSwitch}
                            type="active/inactive"
                            loadingBtn={loadingBtn}
                            rowData={switchData}
                            isActive={switchData?.partIsActive}
                            headerText={`Are you sure you want to ${switchData?.partIsActive ? "Inactive" : "Active"} this Part?`}
                        />
                    </div>
                    <NavigationComponent title={`Parts Management`} addNewHandleDialog={handleDialog} />

                    <div className="card-ml-1 dark-bg">
                        <DataTable
                            header={
                                <div className="flex justify-content-end custom-alignment" style={{ display: "flex", alignItems: "center" }}>
                                    <span className="mr-2 font-semibold text-base">Status:</span>

                                    <Dropdown
                                        className=""
                                        style={{ width: "215px" }}
                                        options={statusOptions}
                                        optionLabel="name"
                                        optionValue="id"
                                        value={statusValue}
                                        onChange={(e) => setStatusValue(e.target.value)}
                                        placeholder="Select Status"
                                        itemTemplate={optionTemplate}
                                        valueTemplate={selectedItemTemplate}
                                    />
                                    <span className="p-input-icon-right">
                                        <i className="pi pi-search" />
                                        <InputText className="Input__Round ml-3" placeholder="Search..." value={globalFilterValue} onChange={onGlobalFilterChange} />
                                    </span>
                                </div>
                            }
                            filters={filters}
                            globalFilterFields={["part_name", "part_no", "makerName", "sku_no", "partVariantBulkPrice", "partVariantPrice", "updatedVariant"]}
                            responsive={true}
                            responsiveLayout="scroll"
                            stripedRows
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            value={filteredParts}
                            emptyMessage="No records found"
                        >
                            <Column body={ImageTemplate} header="Image" style={{ width: "150px" }} />
                            <Column field="part_name" header="Part Name" style={{ width: "150px" }} bodyStyle={{ textAlign: "center", overflow: "visible" }} />
                            <Column field="part_no" header="Part Number" style={{ width: "150px" }} />
                            <Column field="sku_no" header="SKU Number" style={{ width: "150px" }} />
                            <Column field="partVariantName" header="Variant Name" style={{ width: "150px" }} />
                            <Column body={costTemplate} header="Cost" style={{ width: "150px" }} />
                            <Column body={createdDateAndTimeBodyTemplate} header="Created On" style={{ width: "190px" }} />
                            {!userRole?.toLowerCase().includes("user") && <Column body={statusTemplate} header="Status" style={{ width: "90px" }} />}
                            <Column body={actionTemplate} header="Action" style={{ width: "150px" }} />
                        </DataTable>
                        <Modal />
                    </div>
                </>
            )}
        </>
    );
};

export default ManagePart;
