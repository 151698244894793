import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch } from "react-redux";
import editIcon from "../../assets/png/edit.png";
import deleteIcon from "../../assets/png/delete.png";
import viewIcon from "../../assets/png/view.png";
import { Dialog } from "primereact/dialog";
import Modal from "../../components/modal/Modal";
import { handleGetRequest } from "../../services/GetTemplate";
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import AddUser from "./AddUser";
import EditViewUser from "./EditViewUser";
import Loading from "../../components/Loader";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { handlePatchRequest } from "../../services/PatchTemplate";
import NavigationComponent from "../../components/NavigationComponent";

const ManageUsers = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentModal, setCurrentModal] = useState(null);
    const [isEditable, setIsEditAble] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        updatedStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [switchModal, setSwitchModal] = useState(false);
    const [switchData, setSwitchData] = useState(null);
    const [statusValue, setStatusValue] = useState("all");
    const [filteredUsers, setFilteredUsers] = useState([]);

    const userIdStr = localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails");
    const userId = JSON.parse(userIdStr);

    const statusOptions = [
        { id: "all", name: "All" },
        { id: "active", name: "Active" },
        { id: "inactive", name: "Inactive" },
    ];
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const dispatch = useDispatch();
    const [userdataId, setUserDataId] = useState();
    const [modalData, setModalData] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ""));
    };

    const statusTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                <InputSwitch
                    className="edit-btn ml-1 mt-2"
                    checked={rowData.isActive === true}
                    onChange={() => {
                        handleSwitchModalOpen();
                        setSwitchData(rowData);
                    }}
                />
            </div>
        );
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                <Button className="edit-btn ml-1 px-2" onClick={() => handleEditUsers(rowData)}>
                    <img src={editIcon} alt="Edit" />
                </Button>
                <Button className="edit-btn ml-1 px-2" onClick={() => handleViewUsers(rowData)}>
                    <img src={viewIcon} alt="Edit" />
                </Button>
                <Button
                    className="edit-btn ml-1 px-2"
                    disabled={userId?.data?.role === "User" ? true : false}
                    onClick={() => {
                        handleOpenModal();
                        setModalData(rowData);
                    }}
                >
                    <img src={deleteIcon} alt="Delete" />
                </Button>
            </div>
        );
    };

    const handleInputSwitch = async () => {
        setLoadingBtn(true);
        let newData = {
            userId: switchData?.id,
            isActive: switchData?.isActive ? false : true,
        };

        const response = await dispatch(handlePatchRequest(newData, `/api/v1/users/update`, false, true));

        if (response?.status === 200) {
            getUserList();
        }
        setLoadingBtn(false);
        handleSwitchModalClose();
    };

    const handleSwitchModalOpen = () => {
        setSwitchModal(true);
    };
    const handleSwitchModalClose = () => {
        setSwitchModal(false);
    };

    const onHide = () => {
        setCurrentModal(null);
        setDisplayDialog(false);
    };

    const handleDialog = () => {
        setCurrentModal("Add");
        setDisplayDialog(true);
    };

    const handleEditUsers = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setUserDataId(rowData?.id);
        setIsEditAble(true);
    };

    const handleViewUsers = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setUserDataId(rowData?.id);
        setIsEditAble(false);
    };
    const getUserList = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest(`/api/v1/users/getAll/${userId.data.id}`, true));

        setAllUsers(response?.data);
        setLoading(false);
    };

    useEffect(() => {
        getUserList();
    }, []);

    useEffect(() => {
        const updatedResponse = allUsers?.filter((item) => {
            if (statusValue === "active") return item?.isActive;
            else if (statusValue === "inactive") return !item?.isActive;
            else return item;
        });
        setFilteredUsers(updatedResponse);
    }, [statusValue, allUsers]);

    const handleDeleteUser = async () => {
        setLoadingBtn(true);

        const res = await dispatch(handleDeleteRequest(`/api/v1/users/delete/${modalData?.id}`, true, true));
        if (res.status == 200) {
            getUserList();
        }
        setLoadingBtn(false);
        handleCloseModal();
    };

    const optionTemplate = (option) => {
        return <div className={option.className}>{option.name}</div>;
    };
    const selectedItemTemplate = (option) => {
        if (option) {
            return <div className={option.className}>{option.name}</div>;
        }
        return <span>Select Status</span>;
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, "0");
        const dd = String(date.getDate()).padStart(2, "0");
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");

        let ampm = "AM";
        if (hours >= 12) {
            ampm = "PM";
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const headerText = currentModal === "Add" ? "Add User" : isEditable === true ? "Edit User" : "View User";
    const header = <span className="title-border">{headerText}</span>;

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Dialog resizable={false} header={header} visible={displayDialog} baseZIndex={1100} blockScroll style={{ width: "40vw" }} onHide={() => onHide()} draggable={false}>
                        {currentModal === "Add" ? <AddUser onHide={onHide} getUserList={getUserList} /> : currentModal === "EditView" && <EditViewUser onHide={onHide} userdataId={userdataId} getUserList={getUserList} isEditable={isEditable} />}
                    </Dialog>
                    <div>
                        <Modal onConfirm={handleDeleteUser} isOpen={isModalOpen} onClose={handleCloseModal} rowData={modalData} type="delete" loadingBtn={loadingBtn} headerText="Are you sure you want to delete this User?" />
                    </div>{" "}
                    <div>
                        <Modal
                            isOpen={switchModal}
                            onClose={handleSwitchModalClose}
                            onConfirm={handleInputSwitch}
                            type="active/inactive"
                            loadingBtn={loadingBtn}
                            rowData={switchData}
                            isActive={switchData?.isActive}
                            headerText={`Are you sure you want to ${switchData?.isActive ? "Inactive" : "Active"} this User?`}
                        />
                    </div>
                    <NavigationComponent title={`User Management`} addNewHandleDialog={handleDialog} />
                    <div className="card-ml-1 dark-bg">
                        <DataTable
                            header={
                                <div className="flex justify-content-end custom-alignment" style={{ display: "flex", alignItems: "center" }}>
                                    <span className="mr-2 font-semibold text-base">Status:</span>
                                    <Dropdown
                                        className=""
                                        style={{ width: "215px" }}
                                        options={statusOptions}
                                        optionLabel="name"
                                        optionValue="id"
                                        value={statusValue}
                                        onChange={(e) => setStatusValue(e.target.value)}
                                        placeholder="Select Status"
                                        itemTemplate={optionTemplate}
                                        valueTemplate={selectedItemTemplate}
                                    />
                                    <span className="p-input-icon-right">
                                        <i className="pi pi-search" />
                                        <InputText className="Input__Round ml-3" placeholder="Search..." value={globalFilterValue} onChange={onGlobalFilterChange} />
                                    </span>
                                </div>
                            }
                            responsive={true}
                            filters={filters}
                            globalFilterFields={["name", "phone", "role", "email", "updatedStatus"]}
                            responsiveLayout="scroll"
                            stripedRows
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            value={filteredUsers}
                            emptyMessage="No records found"
                            paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        >
                            <Column field="name" header="Name" style={{ width: "150px" }}></Column>
                            <Column field="phone" header="Phone No." style={{ width: "150px" }}></Column>
                            <Column field="role" header="Role" style={{ width: "150px" }}></Column>
                            <Column field="email" header="Email" style={{ width: "150px" }}></Column>
                            <Column body={createdDateAndTimeBodyTemplate} header="Created On" style={{ width: "150px" }}></Column>
                            <Column body={statusTemplate} header="Status" style={{ width: "50px" }}></Column>
                            <Column header="Action" body={actionTemplate} style={{ width: "150px" }} />
                        </DataTable>

                        <Modal />
                    </div>
                </>
            )}
        </>
    );
};

export default ManageUsers;
