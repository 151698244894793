import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import editIcon from "../../../assets/png/edit.png";
import { handleGetRequest } from "../../../services/GetTemplate";
import Modal from "../../../components/modal/Modal";
import { handleDeleteRequest } from "../../../services/DeleteTemplate";
import deleteIcon from "../../../assets/png/delete.png";
import viewIcon from "../../../assets/png/view.png";
import EditViewInventory from "../Components/EditViewInventory";
import Loading from "../../../components/Loader";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { InputSwitch } from "primereact/inputswitch";
import { handlePutRequest } from "../../../services/PutTemplate";
import { Dropdown } from "primereact/dropdown";
import NavigationComponent from "../../../components/NavigationComponent";
import Paginator from "../../../components/Paginator";

const InventoryCart = () => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allInventory, setAllInventory] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [inventoryId, setInventoryId] = useState(null);
    const [inventoryFilter, setInventoryFilter] = useState("part");

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        partMakerName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [switchModal, setSwitchModal] = useState(false);
    const [switchData, setSwitchData] = useState(null);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [statusValue, setStatusValue] = useState("all");
    const [filteredInventory, setFilteredInventory] = useState([]);

    const [recordCount, setRecordCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [quotationsCount, setQuotationsCount] = useState(0);

    const userIdStr = localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails");

    const userId = JSON.parse(userIdStr);
    const userRole = userId?.data?.role;

    const statusOptions = [
        { id: "all", name: "All" },
        { id: "active", name: "Active" },
        { id: "inactive", name: "Inactive" },
    ];
    const inventoryOptions = [
        // { id: "all", name: "All" },
        { id: "part", name: "Part" },
        { id: "vehicle", name: "Variant" },
    ];

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const onHide = () => {
        setDisplayDialog(false);
    };

    const handleEditInventory = (rowData) => {
        setDisplayDialog(true);
        setInventoryId(rowData?.inventoryId);
    };
    const params = new URLSearchParams({
        limit: recordCount,
        page: currentPage,
        isVehicle: inventoryFilter === "part" ? 0 : 1,
        isActive: statusValue === "all" ? undefined : statusValue === "inactive" ? 0 : 1,
    });
    const getInventoryList = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest(`/api/v1/inventories/getAll?${params}`, false));
        setQuotationsCount(parseInt(response?.data?.totalCount));

        setAllInventory(response?.data?.result);
        setLoading(false);
    };

    useEffect(() => {
        getInventoryList();
    }, [inventoryFilter, statusValue, currentPage, recordCount]);
    useEffect(() => {
        const updatedResponse = allInventory?.filter((item) => {
            if (statusValue === "active") return item?.isActive;
            else if (statusValue === "inactive") return !item?.isActive;
            else return item;
        });
        setFilteredInventory(updatedResponse);
    }, [statusValue, allInventory]);

    const handleDeleteInventory = async () => {
        setLoadingBtn(true);
        const response = await dispatch(handleDeleteRequest(`/api/v1/inventories/delete/${modalData?.inventoryId}`, true, true));
        if (response.status == 200) {
            getInventoryList();
        }
        setLoadingBtn(false);
        handleCloseModal();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ""));
    };

    const statusTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                <InputSwitch
                    className="edit-btn ml-1 mt-2"
                    checked={rowData.isActive == "1"}
                    onChange={() => {
                        handleSwitchModalOpen();
                        setSwitchData(rowData);
                    }}
                />
            </div>
        );
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                {/* {!userRole?.toLowerCase().includes("user") && (
                    <InputSwitch
                        className="edit-btn ml-3 mt-2"
                        checked={rowData.isActive == "1"}
                        onChange={() => {
                            handleSwitchModalOpen();
                            setSwitchData(rowData);
                        }}
                    />
                )} */}
                {!userRole?.toLowerCase().includes("user") && (
                    <Button className="edit-btn ml-1 px-2" onClick={() => handleEditInventory(rowData)}>
                        <img src={editIcon} alt="Edit" />
                    </Button>
                )}
                <Link to={{ pathname: `/inventorylogs/${rowData.inventoryId}` }} className="edit-btn ml-1 px-2 mt-3">
                    <img src={viewIcon} alt="View Details" />
                </Link>
                {!userRole?.toLowerCase().includes("user") && (
                    <Button
                        className="edit-btn ml-1 px-2"
                        onClick={() => {
                            handleOpenModal();
                            setModalData(rowData);
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" />
                    </Button>
                )}
            </div>
        );
    };

    const handleInputSwitch = async () => {
        setLoadingBtn(true);

        let obj = {
            id: switchData?.inventoryId,
            isActive: switchData?.isActive ? false : true,
            partId: switchData?.isPart ? switchData?.partId : undefined,
            variantId: !switchData?.isPart ? switchData?.variantId : undefined,
            isPart: switchData?.isPart ? true : false,
        };

        const response = await dispatch(handlePutRequest(obj, `/api/v1/inventories/update`, false, true));

        if (response?.status === 200) {
            getInventoryList();
        }
        setLoadingBtn(false);
        handleSwitchModalClose();
    };

    const handleSwitchModalOpen = () => {
        setSwitchModal(true);
    };
    const handleSwitchModalClose = () => {
        setSwitchModal(false);
    };

    const optionTemplate = (option) => {
        return <div className={option.className}>{option.name}</div>;
    };
    const selectedItemTemplate = (option) => {
        if (option) {
            return <div className={option.className}>{option.name}</div>;
        }
        return <span>Select Status</span>;
    };

    const partCostTemplate = (rowData) => {
        return ` ${rowData?.partCost} ${rowData?.partCurrencyCode}`;
    };
    const variantCostTemplate = (rowData) => {
        return ` ${rowData?.variantCost} ${rowData?.variantCurrencyCode}`;
    };

    const headerText = "Edit Inventory";
    const header = <span className="title-border">{headerText}</span>;

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Dialog resizable={false} header={header} visible={displayDialog} blockScroll style={{ width: "40vw" }} onHide={() => onHide()} draggable={false}>
                        <EditViewInventory onHide={onHide} inventoryId={inventoryId} getInventoryList={getInventoryList} />
                    </Dialog>

                    <div>
                        <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleDeleteInventory} rowData={modalData} type="delete" loadingBtn={loadingBtn} headerText="Are you sure you want to delete this Inventory?" />
                    </div>
                    <div>
                        <Modal
                            isOpen={switchModal}
                            onClose={handleSwitchModalClose}
                            onConfirm={handleInputSwitch}
                            type="active/inactive"
                            loadingBtn={loadingBtn}
                            rowData={switchData}
                            isActive={switchData?.isActive}
                            headerText={`Are you sure you want to ${switchData?.isActive ? "Inactive" : "Active"} this Inventory?`}
                        />
                    </div>
                    <NavigationComponent
                        title={`Inventory Cart`}
                        isSearchBar
                        dropdownOptions={inventoryOptions}
                        dropdownValue={inventoryFilter}
                        dropdownOnChange={(e) => {
                            setInventoryFilter(e.target.value);
                        }}
                    />

                    {inventoryFilter == "part" ? (
                        <div className="card-ml-1 dark-bg">
                            <DataTable
                                header={
                                    <div className="flex justify-content-end custom-alignment" style={{ display: "flex", alignItems: "center" }}>
                                        {" "}
                                        <span className="mr-2 font-semibold text-base">Status:</span>
                                        <Dropdown
                                            className=""
                                            style={{ width: "215px" }}
                                            options={statusOptions}
                                            optionLabel="name"
                                            optionValue="id"
                                            value={statusValue}
                                            onChange={(e) => setStatusValue(e.target.value)}
                                            placeholder="Select Status"
                                            itemTemplate={optionTemplate}
                                            valueTemplate={selectedItemTemplate}
                                        />
                                        <span className="p-input-icon-right">
                                            <i className="pi pi-search" />
                                            <InputText className="Input__Round ml-3" placeholder="Search..." value={globalFilterValue} onChange={onGlobalFilterChange} />
                                        </span>
                                    </div>
                                }
                                responsive={true}
                                filters={filters}
                                globalFilterFields={["partMakerName", "partName", "quantity"]}
                                responsiveLayout="scroll"
                                stripedRows
                                value={filteredInventory}
                                emptyMessage="No records found"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            >
                                <Column field="partName" header="Part Name" style={{ width: "150px" }}></Column>
                                <Column field="quantity" header="Quantity" style={{ width: "150px" }}></Column>
                                <Column field="variantName" header="Variant Name" style={{ width: "150px" }}></Column>
                                <Column body={partCostTemplate} header="Part Cost" style={{ width: "150px" }}></Column>
                                {!userRole?.toLowerCase().includes("user") && <Column body={statusTemplate} header="Status" style={{ width: "90px" }}></Column>}
                                <Column header="Action" body={actionTemplate} style={{ width: "150px" }} />
                            </DataTable>
                        </div>
                    ) : (
                        <div className="card-ml-1 dark-bg">
                            <DataTable
                                header={
                                    <div className="flex justify-content-end custom-alignment" style={{ display: "flex", alignItems: "center" }}>
                                        <span className="mr-2 font-semibold text-base">Status:</span>

                                        <Dropdown
                                            className=""
                                            style={{ width: "215px" }}
                                            options={statusOptions}
                                            optionLabel="name"
                                            optionValue="id"
                                            value={statusValue}
                                            onChange={(e) => setStatusValue(e.target.value)}
                                            placeholder="Select Status"
                                            itemTemplate={optionTemplate}
                                            valueTemplate={selectedItemTemplate}
                                        />
                                        <span className="p-input-icon-right">
                                            <i className="pi pi-search" />
                                            <InputText className="Input__Round ml-3" placeholder="Search..." value={globalFilterValue} onChange={onGlobalFilterChange} />
                                        </span>
                                    </div>
                                }
                                responsive={true}
                                filters={filters}
                                globalFilterFields={["partMakerName", "partName", "quantity"]}
                                responsiveLayout="scroll"
                                stripedRows
                                value={filteredInventory}
                                emptyMessage="No records found"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            >
                                <Column field="variantName" header="Variant Name" style={{ width: "150px" }}></Column>
                                <Column field="variantMakerName" header="Variant Maker Name" style={{ width: "150px" }}></Column>
                                <Column field="variantModelName" header="Variant Model Name" style={{ width: "150px" }}></Column>
                                <Column field="quantity" header="Quantity" style={{ width: "150px" }}></Column>
                                <Column body={variantCostTemplate} header="Variant Cost" style={{ width: "150px" }}></Column>
                                {!userRole?.toLowerCase().includes("user") && <Column body={statusTemplate} header="Status" style={{ width: "90px" }}></Column>}
                                <Column header="Action" body={actionTemplate} style={{ width: "150px" }} />
                            </DataTable>
                        </div>
                    )}
                    <Paginator totalPageCount={totalPageCount} currentPage={currentPage} recordCount={recordCount} setRecordCount={setRecordCount} setCurrentPage={setCurrentPage} setTotalPageCount={setTotalPageCount} quotationsCount={quotationsCount} />
                </>
            )}
        </>
    );
};

export default InventoryCart;
