import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { handlePostRequest } from "../../../../services/PostTemplate";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../../../services/GetTemplate";
import classNames from "classnames";
import SecuredFileChange from "../../../../components/SecuredFileChange/SecuredFileChange";
import { baseURL } from "../../../../Config";
import { Dialog } from "primereact";
import { Carousel } from "primereact/carousel";

const AddVariant = ({ onHide, getVariantList }) => {
    const dispatch = useDispatch();
    const [vehicleModel, setVehicleModel] = useState([]);
    const [maker, setMaker] = useState([]);
    const [category, setCategory] = useState([]);
    const [transmission, setTransmission] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [powerType, setPowerType] = useState([]);
    const [featureList, setFeatureList] = useState([]);
    const [selectedFileError, setSelectedFileError] = useState(null);
    const [selectedFileArray, setSelectedFileArray] = useState([]);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [displayImageDialog, setDisplayImageDialog] = useState(false);

    const variantImageRef = useRef(null);

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            ?.matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .required("This field is required"),
        makerId: Yup.string().required("This field is required"),
        modelId: Yup.string().required("This field is required"),
        categoryId: Yup.string().required("This field is required"),
        transmissionId: Yup.string().required("This field is required"),
        quantity: Yup.string()?.required("Quantity should be atleast 1"),
        yearId: Yup.string().required("This field is required").nullable(),
        powerTypeId: Yup.string().required("This field is required"),
        cost: Yup.number().nullable(),
        currencyCode: Yup.string().when("cost", {
            is: (cost) => cost !== undefined && cost !== null && cost !== "",
            then: Yup.string().required("Please select currency"),
            otherwise: Yup.string(),
        }),
        mileage: Yup.number().nullable(),
        mileageUnit: Yup.string().when("mileage", {
            is: (mileage) => mileage !== undefined && mileage !== null && mileage !== "",
            then: Yup.string().required("Please select milage unit"),
            otherwise: Yup.string(),
        }),
        images: Yup.string().when([], {
            is: () => selectedFileArray.length === 0,
            then: Yup.string().required("You must upload at least one image"),
            otherwise: Yup.string(),
        }),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            modelId: "",
            cost: "",
            currencyCode: "AED",
            feature: [],
            quantity: "1",
            categoryId: "",
            transmissionId: "",
            yearId: "",
            makerId: "",
            powerTypeId: "",
            images: "",
        },

        onSubmit: async (data) => {
            setLoadingBtn(true);

            let obj = {
                name: data?.name,
                modelId: data?.modelId,
                cost: data?.cost ? data?.cost : undefined,
                currencyCode: data?.currencyCode ? data?.currencyCode?.toString() : undefined,
                feature: data?.feature && data.feature.length > 0 ? data.feature.join(",") : undefined,
                quantity: data?.quantity ? data?.quantity : undefined,
                categoryId: data?.categoryId,
                transmissionId: data?.transmissionId,
                yearId: new Date(data?.yearId).getFullYear(),
                makerId: data?.makerId,
                powerTypeId: data?.powerTypeId,
                images: selectedFileArray ? selectedFileArray : null,
            };
            const response = await dispatch(handlePostRequest(obj, "/api/v1/variant/create", false, true));
            if (response?.status === 200) {
                getVariantList();
                onHide();
            }
            setLoadingBtn(false);
        },
    });

    const getCategory = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/category/getAllP", false));
        if (response) {
            const filteredCategories = response?.data?.filter((item) => {
                return item?.isActive === true;
            });
            setCategory(filteredCategories);
        }
    };
    const getMaker = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/makers/getAllP", false));
        if (response) {
            const filteredMakers = response?.data?.filter((item) => {
                return item?.isActive === true;
            });
            setMaker(filteredMakers);
        }
    };

    const getVehiclemodel = async () => {
        const filter = [];
        if (formik?.values?.makerId) {
            filter.push(`makerId=${formik?.values?.makerId}`);
        }
        if (formik?.values?.categoryId) {
            filter.push(`categoryId=${formik?.values?.categoryId}`);
        }
        const filterString = filter.join("&");
        const response = await dispatch(handleGetRequest(`/api/v1/models/getAllP?${filterString}`, false));
        if (response) {
            const filteredModels = response?.data?.filter((item) => {
                return item?.isActive === true;
            });
            setVehicleModel(filteredModels);
        }
    };

    const gettransmission = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/transmission/getAll", false));
        if (response) {
            setTransmission(response?.data);
        }
    };

    const getCurrencies = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/currencies/getAll", false));
        if (response) {
            setCurrencies(response?.data);
        }
    };

    const getPowerType = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/powertypes/getAll", true));
        if (response) {
            setPowerType(response?.data);
        }
    };
    const getFeatureList = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/feature", false));
        if (response) {
            const filteredFeatures = response?.data?.filter((item) => {
                return item?.isActive == true;
            });
            setFeatureList(filteredFeatures);
        }
    };

    const handleDeleteImage = (index) => {
        setSelectedFileArray((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };
    const previewImage = () => {
        setDisplayImageDialog(true);
    };
    const closeDisplayDialog = () => {
        setDisplayImageDialog(false);
    };

    const imagePreviewer = (file) => {
        return (
            <div className="p-field col-12 md:col-12">
                <img src={file} style={{ maxWidth: "100%", maxHeight: "100%", width: "900px", height: "500px", objectFit: "contain" }} />
            </div>
        );
    };
    const responsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 3,
            numScroll: 3,
        },
    ];

    const handleVehicleIcon = () => {
        variantImageRef.current.click();
    };

    // for multiple image upload
    function handleVariantImageChange(event) {
        setSelectedFileError("");
        if (selectedFileArray?.length >= 6) {
            setSelectedFileError("You can only upload a maximum of 6 images.");
            return;
        }
        const selectedFile = event.target.files[0];
        const fileTypes = ["image"];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setSelectedFileError(error);
        } else if (newFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedFileArray((prevItems) => [...prevItems, base64String]);
            };
            reader.readAsDataURL(newFile);
        }
    }

    useEffect(() => {
        getCategory();
        getVehiclemodel();
        gettransmission();
        getCurrencies();
        getMaker();
        getPowerType();
        getFeatureList();
    }, []);

    useEffect(() => {
        if (formik?.values?.makerId != "" && formik?.values?.categoryId != "") {
            getVehiclemodel();
        }
    }, [formik?.values?.makerId, formik?.values?.categoryId]);

    const currentYear = new Date().getFullYear();
    const maxDate = new Date(currentYear, 11, 31);

    const countryTemplate = (option) => {
        return (
            <div className="country-item">
                <img alt={option?.text} src={`${baseURL}/${option?.image}`} className={`flag flag`} />
                <span className="ml-2">{option.text}</span>
            </div>
        );
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <Dialog visible={displayImageDialog} blockScroll style={{ width: "50vw" }} maskClassName="zindex9999" onHide={() => closeDisplayDialog()} draggable={false}>
                {selectedFileArray.length > 1 ? (
                    <Carousel value={selectedFileArray || []} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular={false} itemTemplate={imagePreviewer} />
                ) : (
                    <img src={selectedFileArray[0]} style={{ maxWidth: "100%", maxHeight: "100%", width: "900px", height: "500px", objectFit: "contain" }} />
                )}
            </Dialog>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="field col-12 md:col-6">
                        <label> Variant Name </label>
                        <span className="Label__Required">*</span>
                        <InputText placeholder="Enter Variant Name" className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__Round")} id="name" name="name" type="text" maxLength={20} value={formik.values.name} onChange={formik.handleChange} />
                        {getFormErrorMessage("name")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Maker </label>
                        <span className="Label__Required">*</span>
                        <Dropdown
                            placeholder="Select Maker"
                            className={classNames({ "p-invalid": isFormFieldValid("makerId") }, "Dropdown__Round")}
                            id="makerId"
                            name="makerId"
                            options={maker}
                            optionValue="id"
                            optionLabel="name"
                            value={formik.values.makerId}
                            filter
                            showClear={!!formik?.values?.makerId}
                            filterBy="model_name"
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            onChange={(e) => {
                                formik?.setFieldValue("makerId", e.target.value ? e.target.value : "");
                            }}
                        />
                        {getFormErrorMessage("makerId")}
                    </div>{" "}
                    <div className="field col-12 md:col-6">
                        <label> Category </label>
                        <span className="Label__Required">*</span>
                        <Dropdown
                            placeholder="Select Category"
                            className={classNames({ "p-invalid": isFormFieldValid("categoryId") }, "Dropdown__Round")}
                            id="categoryId"
                            name="categoryId"
                            options={category}
                            optionValue="id"
                            optionLabel="name"
                            value={formik.values.categoryId}
                            filter
                            showClear={!!formik?.values?.categoryId}
                            filterBy="name"
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            onChange={(e) => {
                                formik?.setFieldValue("categoryId", e.target.value ? e.target.value : "");
                            }}
                        />
                        {getFormErrorMessage("categoryId")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Vehicle Model </label>
                        <span className="Label__Required">*</span>
                        <Dropdown
                            placeholder="Select Vehicle Model"
                            className={classNames({ "p-invalid": isFormFieldValid("modelId") }, "Dropdown__Round")}
                            id="modelId"
                            name="modelId"
                            options={vehicleModel}
                            optionValue="id"
                            optionLabel="model_name"
                            value={formik.values.modelId}
                            filter
                            showClear={!!formik?.values?.modelId}
                            filterBy="model_name"
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            onChange={(e) => {
                                formik?.setFieldValue("modelId", e.target.value ? e.target.value : "");
                            }}
                        />
                        {getFormErrorMessage("modelId")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Transmission </label>
                        <span className="Label__Required">*</span>
                        <Dropdown
                            placeholder="Select Transmission"
                            className={classNames({ "p-invalid": isFormFieldValid("transmissionId") }, "Dropdown__Round")}
                            id="transmissionId"
                            name="transmissionId"
                            options={transmission}
                            optionValue="id"
                            optionLabel="transmission"
                            value={formik.values.transmissionId}
                            filter
                            showClear={!!formik?.values?.transmissionId}
                            filterBy="transmission"
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            onChange={(e) => {
                                formik?.setFieldValue("transmissionId", e.target.value ? e.target.value : "");
                            }}
                        />
                        {getFormErrorMessage("transmissionId")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="yearId"> Year </label>
                        <span className="Label__Required">*</span>
                        <Calendar id="yearId" name="yearId" value={formik.values.yearId} onChange={formik.handleChange} view="year" dateFormat="yy" maxDate={maxDate} placeholder="Select Year" className={classNames({ "p-invalid": isFormFieldValid("yearId") }, "Dropdown__Round")} />

                        {getFormErrorMessage("yearId")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Quantity </label>
                        <span className="Label__Required">*</span>
                        <InputText
                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                            placeholder="Enter Quantity"
                            className={classNames({ "p-invalid": isFormFieldValid("quantity") }, "Input__Round")}
                            id="quantity"
                            name="quantity"
                            type="text"
                            maxLength={7}
                            value={formik.values.quantity}
                            onChange={formik.handleChange}
                        />
                        {getFormErrorMessage("quantity")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Power Type </label>
                        <span className="Label__Required">*</span>
                        <Dropdown
                            placeholder="Select Power Type"
                            className={classNames({ "p-invalid": isFormFieldValid("powerTypeId") }, "Dropdown__Round")}
                            id="powerTypeId"
                            name="powerTypeId"
                            options={powerType}
                            optionValue="id"
                            optionLabel="power_Type"
                            value={formik.values.powerTypeId}
                            filter
                            showClear={!!formik?.values?.powerTypeId}
                            filterBy="power_Type"
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            onChange={(e) => {
                                formik?.setFieldValue("powerTypeId", e.target.value ? e.target.value : "");
                            }}
                        />
                        {getFormErrorMessage("powerTypeId")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Feature </label>
                        <span className="Label__Required"></span>{" "}
                        <MultiSelect
                            placeholder="Select Features"
                            className={classNames({ "p-invalid": isFormFieldValid("feature") }, "Dropdown__Round Multiselect__Dropdown")}
                            id="feature"
                            name="feature"
                            options={featureList}
                            optionValue="text"
                            optionLabel="text"
                            value={formik.values.feature}
                            filter
                            showClear={!!formik?.values?.feature}
                            filterBy="text"
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            onChange={(e) => {
                                formik?.setFieldValue("feature", e.target.value ? e.target.value : "");
                            }}
                            itemTemplate={countryTemplate}
                        />
                        {getFormErrorMessage("feature")}
                    </div>
                    <div className="flex field col-12 md:col-6">
                        <div className="field col-12 md:col-6 ">
                            <label> Price </label>
                            <span className="Label__Required"></span>
                            <InputText keyfilter={/^[0-9]*(\.[0-9]*)?$/} className={classNames({ "p-invalid": isFormFieldValid("cost") }, "Input__Round")} id="cost" maxLength={8} name="cost" type="text" value={formik.values.cost} onChange={formik.handleChange} placeholder="Enter Price" />
                            {getFormErrorMessage("cost")}
                        </div>{" "}
                        <div className="field col-12 md:col-6 ml-0">
                            <label> Currency </label>
                            <span className="Label__Required"></span>
                            <Dropdown
                                placeholder="Select CurrencyCode"
                                className={classNames({ "p-invalid": isFormFieldValid("currencyCode") }, "Dropdown__Round")}
                                id="currencyCode"
                                name="currencyCode"
                                options={currencies}
                                optionValue="code"
                                optionLabel="code"
                                value={formik.values.currencyCode}
                                filter
                                showClear={!!formik?.values?.currencyCode}
                                filterBy="code"
                                showFilterClear={true}
                                resetFilterOnHide={true}
                                onChange={(e) => {
                                    formik?.setFieldValue("currencyCode", e.target.value ? e.target.value : "");
                                }}
                            />
                            {getFormErrorMessage("currencyCode")}
                        </div>
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Upload Images </label> <span className="Label__Required">*</span>
                        <div className="file-input-container " onClick={handleVehicleIcon}>
                            <input type="file" accept="image/*" style={{ display: "none" }} ref={variantImageRef} onChange={handleVariantImageChange} disabled={selectedFileArray?.length >= 6} />
                            <input className={classNames({ "p-invalid": isFormFieldValid("images") }, "custom-file p-inputtext p-component Input__Round")} type="text" placeholder="Choose a file..." value={formik.values.images} readOnly disabled={selectedFileArray?.length >= 6} />
                            <span className="file-upload-icon">
                                <i className="pi pi-paperclip" />
                            </span>
                        </div>
                        {selectedFileError ? <small className="p-error">{selectedFileError}</small> : getFormErrorMessage("images")}
                    </div>{" "}
                    <div className="field col-12 md:col-12">
                        <div>
                            <label> Uploaded Images </label>
                        </div>
                        <div className=" border-solid border-900 border-2 border-round p-5 mt-2">
                            {selectedFileArray.map((item, index) => {
                                return (
                                    <div key={index} style={{ position: "relative", display: "inline-block", margin: "5px", width: "110px", height: "110px" }}>
                                        <i className="pi pi-times closingIcon" onClick={() => handleDeleteImage(index)} style={{ cursor: "pointer", position: "absolute", top: "0", right: "0" }} />
                                        <img
                                            crossOrigin="anonymous"
                                            src={item}
                                            style={{ width: "90px", height: "90px", objectFit: "contain" }}
                                            alt="Variant Image"
                                            onClick={() => {
                                                previewImage();
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        <Button className="Save-Button ml-2" label="Add" type="submit" loading={loadingBtn} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddVariant;
