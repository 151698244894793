import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch } from "react-redux";
import editIcon from "../../../assets/png/edit.png";
import { handleGetRequest } from "../../../services/GetTemplate";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loader";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import NavigationComponent from "../../../components/NavigationComponent";
import { Dropdown } from "primereact/dropdown";
import Paginator from "../../../components/Paginator";

const QueryManagement = () => {
    const dispatch = useDispatch();
    const [allQuotations, setAllQuotations] = useState([]);
    const [quotationsCount, setQuotationsCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [statusValue, setStatusValue] = useState("pending");
    const [queryFilter, setQueryFilter] = useState("all");

    //paginator
    const [recordCount, setRecordCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        clientName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        quotationStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const queryOptions = [
        { id: "all", name: "All Queries" },
        { id: "part", name: "Part Queries" },
        { id: "vehicle", name: "Variant Queries" },
    ];
    const statusOptions = [
        { id: "pending", name: "Pending" },
        // { id: "responded", name: "Responded", className: "status-active" },
        { id: "approved", name: "Approved" },
        { id: "onhold", name: "On Hold" },
        { id: "rejected", name: "Rejected" },
        { id: "processed", name: "Processed" },
        // { id: "delivered", name: "Delivered", className: "status-delivered" },
    ];

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ""));
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="">
                <Link to={{ pathname: `/viewquery/${rowData.quotationId}` }} className="edit-btn ml-3">
                    <img src={editIcon} alt="Edit" />
                </Link>
            </div>
        );
    };

    const queryTemplate = (rowData) => {
        const isReplied = rowData?.isReplied;
        const queryText = rowData?.clientQuery ? rowData?.clientQuery : "";
        const truncatedText = queryText?.length <= 30 ? queryText : queryText?.substring(0, 30) + "...";

        return (
            <span data-pr-tooltip={queryText} style={{ fontWeight: isReplied ? "bold" : "normal", cursor: "pointer", color: isReplied ? "black" : "" }}>
                {truncatedText}
            </span>
        );
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isReplied = rowData?.isReplied;
        const isoDate = rowData?.quotationAddedDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, "0");
        const dd = String(date.getDate()).padStart(2, "0");
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");

        let ampm = "AM";
        if (hours >= 12) {
            ampm = "PM";
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p style={{ fontWeight: isReplied ? "bold" : "normal", color: isReplied ? "black" : "" }}>{formattedDateTime}</p>;
    };

    const params = new URLSearchParams({
        limit: recordCount,
        page: currentPage,
        isVehicle: queryFilter === "all" ? undefined : queryFilter === "part" ? 0 : 1,
        status: statusValue,
    });
    const getQuotationsList = async () => {
        setLoading(true);

        const response = await dispatch(handleGetRequest(`/api/v1/qoutation/getAll?${params}`, false));
        setAllQuotations(response?.data?.result);
        setQuotationsCount(parseInt(response?.data?.totalCount));
        setLoading(false);
    };

    useEffect(() => {
        getQuotationsList();
    }, [queryFilter, statusValue, currentPage, recordCount]);

    const clientNameTemplate = (rowData) => {
        const isReplied = rowData?.isReplied;
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                <span
                    style={{
                        display: "inline-block",
                        width: "8px",
                        height: "8px",
                        backgroundColor: isReplied ? "#00ff01" : "transparent", // Green if isReplied is true, otherwise transparent
                        borderRadius: "50%",
                        marginRight: "5px",
                    }}
                ></span>
                <span style={{ fontWeight: isReplied ? "bold" : "normal", color: isReplied ? "black" : "" }}>{rowData?.clientName}</span>
            </div>
        );
    };

    const phoneTemplate = (rowData) => {
        const isReplied = rowData?.isReplied;
        return <span style={{ fontWeight: isReplied ? "bold" : "normal", color: isReplied ? "black" : "" }}>{rowData?.clientPhone}</span>;
    };
    const emailTemplate = (rowData) => {
        const isReplied = rowData?.isReplied;
        return <span style={{ fontWeight: isReplied ? "bold" : "normal", color: isReplied ? "black" : "" }}>{rowData?.clientEmail}</span>;
    };
    const quotationIdTemplate = (rowData) => {
        const isReplied = rowData?.isReplied;
        return <span style={{ fontWeight: isReplied ? "bold" : "normal", color: isReplied ? "black" : "" }}>{rowData?.quotationId}</span>;
    };

    const optionTemplate = (option) => {
        return <div className={option.className}>{option.name}</div>;
    };
    const selectedItemTemplate = (option) => {
        if (option) {
            return <div className={option.className}>{option.name}</div>;
        }
        return <span>Select Status</span>;
    };

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <NavigationComponent
                        title={`Query Management`}
                        isSearchBar
                        dropdownOptions={queryOptions}
                        dropdownValue={queryFilter}
                        dropdownOnChange={(e) => {
                            setQueryFilter(e.target.value);
                        }}
                    />

                    <div className="card-ml-1 dark-bg">
                        <DataTable
                            header={
                                <div className="flex justify-content-end custom-alignment" style={{ display: "flex", alignItems: "center" }}>
                                    <span className="mr-2 font-semibold text-base">Status:</span>

                                    <Dropdown
                                        className=""
                                        style={{ width: "215px" }}
                                        options={statusOptions}
                                        optionLabel="name"
                                        optionValue="id"
                                        value={statusValue}
                                        onChange={(e) => setStatusValue(e.target.value)}
                                        placeholder="Select Status"
                                        itemTemplate={optionTemplate}
                                        valueTemplate={selectedItemTemplate}
                                        scrollHeight="300px"
                                    />
                                    <span className="p-input-icon-right">
                                        <i className="pi pi-search" />
                                        <InputText className="Input__Round ml-3" placeholder="Search..." value={globalFilterValue} onChange={onGlobalFilterChange} />
                                    </span>
                                </div>
                            }
                            filters={filters}
                            globalFilterFields={["clientName", "clientPhone", "clientEmail", "clientCountryName", "clientQuery"]}
                            responsiveLayout="scroll"
                            stripedRows
                            value={allQuotations}
                            emptyMessage="No records found"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        >
                            <Column body={clientNameTemplate} header="Name" style={{ width: "150px" }}></Column>
                            <Column body={phoneTemplate} field="clientPhone" header="Phone No." style={{ width: "150px" }}></Column>
                            <Column body={emailTemplate} field="clientEmail" header="Email" style={{ width: "150px" }}></Column>
                            <Column body={quotationIdTemplate} field="quotationId" header="Quotation Id" style={{ width: "150px" }}></Column>
                            <Column body={queryTemplate} header="Query" style={{ width: "150px" }}></Column>
                            <Column body={createdDateAndTimeBodyTemplate} header="Created On" style={{ width: "150px" }}></Column>

                            <Column header="Action" body={actionTemplate} style={{ width: "150px" }} />
                        </DataTable>
                        <Tooltip target="[data-pr-tooltip]" />
                    </div>
                    <Paginator totalPageCount={totalPageCount ?? 0} currentPage={currentPage ?? 0} recordCount={recordCount ?? 0} setRecordCount={setRecordCount} setCurrentPage={setCurrentPage} setTotalPageCount={setTotalPageCount} quotationsCount={quotationsCount} />
                </>
            )}
        </>
    );
};

export default QueryManagement;
