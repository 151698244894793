import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import React from "react";

const NavigationComponent = ({ title, addNewHandleDialog, isBackButtonLink, isSearchBar, dropdownOnChange, dropdownOptions, dropdownValue }) => {
    const userIdStr = sessionStorage.getItem("login");
    const userId = userIdStr ? JSON?.parse(userIdStr) : "";
    return (
        <div className="card-ml-1 dark-bg p-fluid p-formgrid grid mb-3 pt-3 pb-2 pl-3 pr-3 mt-3 ml-0 mr-0 mb-0">
            <div className="p-field col-12 md:col-6">{title && <h2 style={{ fontWeight: "600", fontSize: "22px", color: "black" }}>{title}</h2>}</div>
            <div className="p-field col-12 md:col-6">
                {addNewHandleDialog && (
                    <React.Fragment>
                        {userId?.data?.role?.toLowerCase() !== "user" && (
                            <Button
                                label="Add New"
                                className="Btn__Add"
                                icon="pi pi-plus-circle"
                                iconPos="left"
                                onClick={() => {
                                    addNewHandleDialog();
                                }}
                            />
                        )}
                    </React.Fragment>
                )}
                {isBackButtonLink && (
                    <Button
                        label="Back"
                        className="Btn__Add mr-2"
                        icon="pi pi-arrow-left"
                        iconPos="left"
                        onClick={() => {
                            isBackButtonLink();
                        }}
                    />
                )}
                {isSearchBar && <Dropdown className="ml-auto" style={{ width: "215px" }} options={dropdownOptions} optionLabel="name" optionValue="id" value={dropdownValue} onChange={dropdownOnChange} />}
            </div>
        </div>
    );
};

export default NavigationComponent;
